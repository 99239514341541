<template>
  <!-- ***** Our Features Section ***** -->
  <section id="features">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Section Heading Area -->
          <div class="section-heading text-center">
            <h2><i class="fa fa-location-arrow"></i> Biosoft Check In</h2>
            <div class="line-title-center"></div>
            <p style="font-size: 16px;">
              ระบบลงเวลาเข้าออฟฟิต หรือลงเวลาออกนอกสถานที่ ไม่ต้องใช้เครื่องสแกนนิ้วพร้อมฟีเจอร์อื่นๆอีกมากมาย
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 col-sm-12">
          <div class="feature-left text-right">
            <div class="feature-single">
              <h4>mCheck IN & OUT <i class="icon-location-pin"></i></h4>
              <p>
                ระบบลงเวลาและสถานที่ผ่านแอปพลิเคชั่นมือถือ ลดค่าใช้จ่ายในการจัดซื้อและซ่อมแซมเครื่องแสกนนิ้ว เพิ่มความสะดวกในการลงเวลา พร้อมถ่ายรูปยืนยันตัวตน
              </p>
            </div>
            <div class="feature-single">
              <h4>mPay Slip <i class="icon-eye"></i></h4>
              <p>
                ระบบแสดงข้อมูลการจ่ายเงิน/สลิปเงินเดือนผ่านแอปพลิเคชั่นมือถือ ช่วยลดค่าใช้จ่ายลดกระดาษในการปริ้นสลิป และเพิ่มความรวดเร็วให้กับพนักงานในการดู
              </p>
            </div>
            <div class="feature-single">
              <h4>mLeave <i class="icon-calendar"></i></h4>
              <p>
                ระบบบันทึกและอนุมัติลาผ่านแอปพลิเคชั่นมือถือ ไม่ว่าจะอยู่ที่ไหน เมื่อไหร ก็สามารถลางานได้แบบสะดวกสบาย จากนั้นผู้มีอำนาจก็สามารถอนุมัติการลาได้เช่นกัน
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
          <div class="feature-img text-center">
            <v-lazy-image
              src="assets/images/mobile/MainBiosoft.png"
              alt="Biosoft Mobile"
            />
          </div>
        </div>

        <div class="col-12 col-lg-4 col-sm-12">
          <div class="feature-right">
            <div class="feature-single">
              <h4><i class="icon-clock"></i> mRequest Overtime</h4>
              <p>
                ระบบขอและอนุมัติโอทีผ่านแอปพลิเคชั่นมือถือ เพิ่มความสะดวกสบายให้กับพนักงานเป็นอย่างมาก ลดการล่าช้า ข้อมูลมาถึงผู้อนุมัติได้ทันทีไม่ว่าจะอยู่ที่ไหน
              </p>
            </div>
            <div class="feature-single">
              <h4><i class="icon-pencil"></i> mService</h4>
              <p>
                ระบบบันทึกการเข้าบริการผ่านแอปพลิเคชั่นมือถือ มอบหมายงานการออกนอกสถานที่ สามารถให้ลูกค้าเซ็นและลงเวลาเข้าออก
              </p>
            </div>
            <div class="feature-single">
              <h4><i class="icon-notebook"></i> mEdit Worktime</h4>
              <p>
                ระบบแก้ไขเวลาการทำงานผ่านแอพพลิเคชั่นมือถือ บริหารจัดการเรื่องกะการทำงานต่างๆของตัวเองได้ สามารถทำได้ด้วยความสะดวกสบาย
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const VLazyImage = () => import("v-lazy-image");
export default {
    name: "HomeFeatures",
    components: {
        VLazyImage
    }
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
